import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CaseStudyCard from './CaseStudyCard';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;
const CaseStudyContainer = styled.div`
  margin-bottom: 30px;
`;
const MoreCaseStudiesButton = styled.button`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  width: fit-content;
  height: 58px;
  background: #ffffff;
  border: 2px solid #ff6d00;
  border-radius: 100px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ff6d00;
  margin-top: 130px;
  margin-bottom: 130px;
  cursor: pointer;
`;
const CaseStudiesHub = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityCaseStudy(sort: { fields: _createdAt, order: DESC }) {
        nodes {
          title
          slug {
            _key
            _type
            current
            source
          }
          customImages {
            altText
            id
            imageFourToFive {
              _type
              _key
              asset {
                url
                altText
              }
            }
            imageOneToOne {
              asset {
                url
              }
            }
            imageSixteenToNine {
              asset {
                url
              }
            }
          }
          excerpt {
            _key
            _rawChildren
            _type
            list
            style
            children {
              _key
              _type
              marks
              text
            }
          }
          _createdAt
        }
      }
    }
  `);

  const [caseStudies, setCaseStudies] = useState(data?.allSanityCaseStudy?.nodes);
  const [visibleCaseStudies, setVisibleCaseStudies] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  const loadMoreCaseStudies = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newVisibleCaseStudies = [...visibleCaseStudies, ...caseStudies.slice(startIndex, endIndex)];
    setVisibleCaseStudies(newVisibleCaseStudies);
    setPage(page + 1);
  };

  useEffect(() => {
    if (data) {
      setCaseStudies(data?.allSanityCaseStudy?.nodes);
      loadMoreCaseStudies();
    }
  }, [data]);

  return (
    <MainContainer>
      {visibleCaseStudies?.map(caseStudy => (
        <CaseStudyContainer>
          <CaseStudyCard caseStudyProp={caseStudy} separator featured={false} reverse={false} hub />
        </CaseStudyContainer>
      ))}
      <MoreCaseStudiesButton onClick={loadMoreCaseStudies} hidden={visibleCaseStudies.length === caseStudies.length}>
        All Case Studies <FontAwesomeIcon icon={faArrowRight} />
      </MoreCaseStudiesButton>
    </MainContainer>
  );
};

export default CaseStudiesHub;
